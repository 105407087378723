import React, {useRef, useEffect} from 'react';
import Col from 'react-materialize/lib/Col';
import Row from 'react-materialize/lib/Row';
import {gsap} from 'gsap'
import { navigate } from 'gatsby';
import logo from '../images/remote-work-man.svg'

const Cta= () => {


  function handleClick() {
    navigate("/signup")
  }
  let btnEl = useRef(null)
  useEffect(() => {
    // gsap.to(svgEl, {opacity: 1, duration: .8, y: -20, ease: "power4.out", delay: .4})
    // gsap.to(textEl, {opacity: 1, duration: .8, y: -20, ease: "slow(0.1, 0.4, false)", delay: .4})
    gsap.to(btnEl, {opacity: 1, duration: .8, y: -20, ease: "slow(0.1, 0.4, false)", delay: .5})
  },[])

  return (
    <section>
      <Row>
        <Col l={6} m={6} s={12}>
          <div className="cta-contain">
            <h4 className="cta-head">Prêt à passer à l'étape suivante en matière de soins à distance?</h4>
            <p className="cta-text color-4">Le processus est tres simple. Inscrivez-vous en cliquant sur ce bouton <span role="img" aria-labelledby="point-down">👇</span>, ensuite vous pourrez chercher le medecin qu'il vous faut. Une fois le medecin trouv&eacute; vous pourrez prendre rendez-vous avec lui, payer par MonCash et vous faire consulter en appel video a partir de votre compte.</p>
            <button 
              className="titlehomecta" 
              onClick={handleClick}
              ref={el => btnEl = el}
            >
              Inscrivez-vous!
            </button>
          </div>
        </Col>
        <Col l={6} m={6} s={12}>
        <div className="cta-img-contain">
          <span className="img-contain">
            <img className="cta-img" src={logo} alt="Adobe XD"  height="60"></img>
          </span>
        </div>
        </Col>
      </Row>
    </section>
  );
};

export default Cta