import React from "react"
import TitleHome from './titlehome'
import Path from "./path";
import { StaticImage } from "gatsby-plugin-image";



const Header = (props) =>{

  
  return (
    <header className="header">
      <Path />
      <TitleHome />
      <StaticImage src="../images/girl.png" alt="Remote patient" height={300} layout="fixed" placeholder="none" className="girl hide-on-large-only" />
      <StaticImage src="../images/girl.png" alt="Remote patient" height={500} layout="fixed" placeholder="none" className="girl hide-on-med-and-down" />
    </header>
  )
}
;
export default Header;