import React from "react"
import Header from "../components/header"
import Seo from "../components/seo"
import Layout from "../components/layout"
import Features from "../components/features"
import Cta from "../components/cta"

const IndexPage = () => {

  return(
  <Layout>
    <Seo title="T&eacute;l&eacute;m&eacute;decine - Consultation en ligne - Haiti "  />
    <Header />
    <div className="home-body">
      <Features />
      <Cta />
    </div>
  </Layout>
)}

export default IndexPage
