import React from 'react'
import Col from 'react-materialize/lib/Col'
import Row from 'react-materialize/lib/Row'
import { FaUserMd, FaAt, FaUpload, FaCalendarCheck, FaAddressCard, FaMoneyCheckAlt } from 'react-icons/fa';

const Features = () => {

  const featureList = [
    {
      title: "Liste des médecins",
      body: "Toute une panoplie de spécialistes à votre disposition. Il vous suffit de rechercher la spécialité dont vous avez besoin et de choisir dans la liste.",
      icon: <FaUserMd className="feature-icon"/>
    },
    // {
    //   title: "Profil des patients",
    //   body: "Tous les patients auront leur profil sur le système et pourront voir l'historique de leurs consultations passées.",
    //   icon: <FaAddressCard className="feature-icon"/>
    // },
    {
      title: "Prise de rendez-vous",
      body: "Si vous vivez dans des régions éloignées, ou vous ne voulez pas vous déplacer, Allodoc est fait pour vous. Nous fournissons une aide immédiate pour les problèmes médicaux non interventionnels en vous rencontrant où que vous soyez.",
      icon: <FaCalendarCheck className="feature-icon"/>
    },
    {
      title: "Notifications par email",
      body: "Après avoir pris rendez-vous, nous vous enverrons une confirmation par email. Cette notification vous offrira également la possibilité d'ajouter le rendez-vous à votre agenda Google ou Apple pour ne pas l'oublier.",
      icon: <FaAt className="feature-icon"/>
    },
    {
      title: "Chargement de documents",
      body: "Le système vous permet de télécharger tous les documents requis pour le processus. Vous pouvez ainsi envoyer directement les résultats du laboratoire et télécharger les prescriptions sans avoir à vous déplacer.",
      icon: <FaUpload className="feature-icon"/>
    },
    {
      title: "Méthode de paiement",
      body: "Le système intègre actuellement Moncash et les carte de crédit comme moyen de paiement pour vos rendez-vous. D'autres méthodes seront ajoutées très prochainement.",
      icon: <FaMoneyCheckAlt className="feature-icon"/>
    },
  ]

  return (
    <section className="color-0-bg">
      <div className="container1">      
        <h3 className="section-head color-3">
          Des fonctionnalités intéressantes dans un système de soins à distance compréhensible, facile à utiliser pour vous procurer une expérience patient extraordinaire.
        </h3>
        <Row>
          {
            featureList.map(feature => {
              return(
                <Col l={4} m={6} s={12} key={feature.title}>
                  <div className="center feature color-3">
                    {feature.icon}
                    <h4 className="feature-head">{feature.title}</h4>
                    <p className="feature-body">{feature.body}</p>
                  </div>
                </Col>
              )
            })
          }
        </Row>
      </div>
    </section>
  )
}

export default Features