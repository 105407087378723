import React from 'react'

export default function Path() {
  return (
    <svg className="Path_3" viewBox="0 0 1361.255 1080.673">
      <path id="Path_3" d="M 0 0 L 590.9696655273438 0 C 590.9696655273438 0 715.2831420898438 -0.7074975371360779 787.1953125 96.6798095703125 C 859.1074829101563 194.0671234130859 712.1060791015625 436.7786254882813 872.6021728515625 560.39892578125 C 1033.098266601563 684.0192260742188 1159.775634765625 617.91015625 1272.505004882813 757.9620971679688 C 1385.234375 898.0140380859375 1358.484252929688 1080.673217773438 1358.484252929688 1080.673217773438 L 1.337469220161438 1080.100708007813 L 0 0 Z">
      </path>
    </svg>
  )
}
