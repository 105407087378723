import React, {useRef, useEffect} from 'react'
import {gsap} from 'gsap'
import { navigate } from 'gatsby';

const TitleHome = () => {
  let headEl = useRef(null)
  let textEl = useRef(null)
  let btnEl = useRef(null)
  useEffect(() => {
    gsap.to(textEl, {opacity: 1, duration: .8, y: -20, ease: "back", delay: .4})
    gsap.to(headEl, {opacity: 1, duration: .8, y: -20, ease: "back", delay: .3})
    gsap.to(btnEl, {opacity: 1, duration: .8, y: -20, ease: "back", delay: .5})
  },[])

  function handleClick() {
    navigate("/signup")
  }
  
  return (
    <div className="titlehome">
      <h2 
        className="titlehomehead"
        ref={el => headEl = el}
      >
        Consultez votre m&eacute;decin<br />Tout en restant<br />Chez vous
      </h2>
      <p 
        className="titlehometext"
        ref={el => textEl = el}      
      >
        {/* Les meilleurs m&eacute;decins d'Ha&iuml;ti et d'ailleurs sont &agrave; votre disposition sans m&ecirc;me vous d&eacute;placer. */}
        &Eacute;vitez les files d'attente. Consultez les meilleurs m&eacute;decins d'Ha&iuml;ti et d'ailleurs sans m&ecirc;me vous d&eacute;placer.
      </p>
      <div className="ctacontainer center">
        <button 
          className="titlehomecta" 
          onClick={handleClick}
          ref={el => btnEl = el}
        >
          Gagnez du temps
        </button>
      </div>
    </div>
  );
};

export default TitleHome;